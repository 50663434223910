<template>
  <ion-header :translucent="true" collapse="fade">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button :auto-hide="false" menu="main-menu"></ion-menu-button>
      </ion-buttons>
      <ion-title>{{ currentTabName }}</ion-title>
      <ion-buttons slot="end" v-if="isLoggedIn">
        <ion-button @click="handleLogout">
          <ion-icon :icon="logOutOutline" slot="icon-only" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonMenuButton
} from '@ionic/vue';
import { logOutOutline } from 'ionicons/icons';
import { useAuthStore } from '@/store/auth';
import { APP_CONFIG } from '@/utils/config';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AppHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonMenuButton
  },
  setup() {
    const authStore = useAuthStore();
    const route = useRoute();
    const isLoggedIn = computed(() => authStore.isLoggedIn);

    const currentTabName = computed(() => {
      const path = route.path;
      if (path === '/tabs' || path === '/tabs/') {
        return 'Home';
      }
      if (path === '/tabs/tab1') {
        return 'Search Products';
      }
      if (path === '/tabs/tab2') {
        return 'Profiles';
      }
      if (path === '/tabs/tab3') {
        return 'Settings';
      }
      return APP_CONFIG.appName;
    });

    const handleLogout = async () => {
      await authStore.logout();
    };

    return {
      isLoggedIn,
      handleLogout,
      logOutOutline,
      currentTabName
    };
  }
});
</script> 

<style scoped>

ion-toolbar {
  /* --background: var(--ion-color-light);
  --border-bottom: 1px solid var(--ion-color-light-shade);
  --color: var(--ion-color-light-contrast);
  /* --padding-top: 5px;
  --padding-bottom: 5px; */ 
  opacity: 0.75;

}

</style>