<template>
  <ion-footer collapse="fade" :translucent="true" >
    <ion-toolbar class="ion-padding-horizontal">
      <ion-text slot="start" class="copyright" style="font-size: 12px;">
        © 2024 Acos Improvers. All rights reserved.
      </ion-text>
      <ion-buttons slot="end" class="nav-links">
        <ion-button class="footer-link" href="https://acosimprovers.com/contact" target="_blank" style="font-size: 12px;">Contact</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>
  
<script lang="ts">
  import { IonContent, IonFooter, IonTitle, IonToolbar, IonText, IonButtons, IonButton } from '@ionic/vue';
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    components: { IonContent, IonFooter, IonTitle, IonToolbar, IonText, IonButtons, IonButton },
  });
</script>
<style scoped>
  ion-toolbar {
    opacity: 0.5;
  }
</style>

