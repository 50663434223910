import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '@/views/tabs/TabsPage.vue';
import { authGuard } from '@/guards/AuthGuard';
import { tokenStorage } from '@/store/auth/persistence/tokenStorage';
import { useAuthStore } from '@/store/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/LoginPage.vue'),
    beforeEnter: async (to, from, next) => {
      const redirectPath = to.query.redirect as string;
      if (redirectPath) {
        const tokenMatch = redirectPath.match(/[?&]token=([^&]+)/);
        if (tokenMatch) {
          const token = tokenMatch[1];
          tokenStorage.setToken(token);
          const authStore = useAuthStore();
          await authStore.checkAuthStatus();
          next('/tabs');
          return;
        }
      }
      next();
    }
  },
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: () => import('@/views/auth/AuthCallback.vue')
  },
  {
    path: '/campaign-overview/:profileId/:profileName',
    name: 'CampaignOverview',
    component: () => import('@/views/reports/CampaignOverview.vue')
  },
  {
    path: '/tabs',
    component: TabsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Tabs',
        component: () => import('@/views/tabs/TabsHome.vue')
      },
      {
        path: 'tab1',
        component: () => import('@/views/tabs/Tab1Page.vue')
      },
      // {
      //   path: 'tab2',
      //   component: () => import('@/views/tabs/Tab2Page.vue')
      // },
      {
        path: 'tab3',
        component: () => import('@/views/tabs/Tab3Page.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach(authGuard);

export default router;
