<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar :translucent="true" slot="bottom">
        <ion-tab-button 
          v-for="tab in tabs" 
          :key="tab.path"
          :tab="tab.id"
          :selected="currentTab === tab.index"
          @click="navigateToTab(tab.path, tab.index)"
        >
          <ion-icon :icon="tab.icon" />
          <ion-label>{{ tab.label }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useIonRouter } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { useTabBarVisibility } from '@/composables/useTabBarVisibility';
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
import {
  homeOutline,
  searchOutline,
  settingsOutline
} from 'ionicons/icons';

interface Tab {
  id: string;
  path: string;
  icon: string;
  label: string;
  index: number;
}

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const ionRouter = useIonRouter();
    const route = useRoute();
    const currentTab = ref(0);

    const tabs: Tab[] = [
      { id: 'tabs', path: '/tabs', icon: homeOutline, label: 'Home', index: 0 },
      { id: 'tab1', path: '/tabs/tab1', icon: searchOutline, label: 'Keywords', index: 1 },
      { id: 'tab3', path: '/tabs/tab3', icon: settingsOutline, label: 'Settings', index: 3 }
    ];

    onMounted(() => {
      const activeTab = tabs.find(tab => route.path.startsWith(tab.path));
      if (activeTab) {
        currentTab.value = activeTab.index;
      }
    });

    const navigateToTab = (path: string, index: number) => {
      const direction = index > currentTab.value ? 'forward' : 'back';
      currentTab.value = index;
      ionRouter.navigate(path, direction);
    };
    
    return {
      tabs,
      navigateToTab,
      currentTab
    }
  }
});
</script>

<style scoped>
ion-tab-bar {
  --ion-tab-bar-background: var(--ion-background-color);
  border: none;
  padding-bottom: var(--ion-safe-area-bottom);
}

ion-tab-button {
  --color-selected: var(--ion-color-primary);
  --padding-bottom: 5px;
}

ion-tab-button[selected] {
  --color: var(--ion-color-primary);
}

ion-icon {
  font-size: 18px;
}
</style> 